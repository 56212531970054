.ResultsList_Loading {
    margin: 30px 0;
    text-align: center;
}

.ResultsList_Ended {
    text-align: center;
    padding: 20px 0;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 12px;
}
